var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
import { updateSettingUserAPI, userInformationAPI } from "../api/API";
import { setAuthInformationUser } from "./authReducer";
import { setActive, setMessageToUserForModal, setModal, setSuccessAlertOpenStatus } from "./modalReducer";
var initialStateUpdateUser = {
    dataToUpdate: {
        about: {
            info: {},
        },
        password: {},
        menu: [],
    },
    pickedMenuItemArray: [],
    typeChoosedBlock: "user-info",
    togglersButtons: [
        { id: 1, typeBlock: "user-info", title: "Данные пользователя", checked: true },
        { id: 2, typeBlock: "change-pass", title: "Изменить пароль", checked: false },
        { id: 3, typeBlock: "configure-menu", title: "Настройка меню", checked: false },
    ],
    arrayForCheckBoxes: [],
    isUpdatingFormActivated: false,
};
var slice = createSlice({
    name: "updateUser",
    initialState: initialStateUpdateUser,
    reducers: {
        setUpdateUser: function (state, action) {
            return action.payload;
        },
        setChangeUserAvatar: function (state, action) {
            return action.payload;
        },
        setChangeUserCover: function (state, action) {
            return action.payload;
        },
        toggleAvatarOrCoverChanged: function (state) {
            state.isAvatarOrCoverChanged = !state.isAvatarOrCoverChanged;
        },
        // помещаются измененные данные о пользователе из полей для ввода
        setUserUpdateField: function (state, action) {
            var _a, _b;
            state.isUpdatingFormActivated = true;
            if (action.payload.field === "email") {
                state.dataToUpdate.about = __assign(__assign({}, state.dataToUpdate.about), (_a = {}, _a[action.payload.field] = action.payload.value, _a));
            }
            else {
                state.dataToUpdate.about.info = __assign(__assign({}, state.dataToUpdate.about.info), (_b = {}, _b[action.payload.field] = action.payload.value, _b));
            }
        },
        setPasswordsFiled: function (state, action) {
            var _a;
            state.isUpdatingFormActivated = true;
            state.dataToUpdate.password = __assign(__assign({}, state.dataToUpdate.password), (_a = {}, _a[action.payload.field] = action.payload.value, _a));
        },
        //переключаются кнопки для формы: Данные о пользователе, Смена пароля, Конфигурация меню
        setCheckedToggleButtons: function (state, action) {
            state.togglersButtons.map(function (button) {
                if (button.id === action.payload.id) {
                    state.typeChoosedBlock = action.payload.typeBlock;
                    return (button.checked = true);
                }
                else {
                    return (button.checked = false);
                }
            });
        },
        //переключается состояние видимости чекбоксов для отображение в главном меню visible - true главное менб, visible - false в пунтке Еще
        setPickCheckboxInMenu: function (state, action) {
            state.arrayForCheckBoxes = state.arrayForCheckBoxes.map(function (checkbox) {
                if (checkbox.id === action.payload.id) {
                    return __assign(__assign({}, checkbox), { visible: !checkbox.visible });
                }
                return __assign({}, checkbox);
            });
        },
        //сетаются указаные поля для редактирования пользоваеля
        setDataToUpdate: function (state, action) {
            state.dataToUpdate = __assign(__assign({}, state.dataToUpdate), action.payload);
        },
        //старый и новый пароль
        setPasswordToUpdate: function (state, action) {
            state.dataToUpdate = __assign(__assign({}, state.dataToUpdate), action.payload);
        },
        //сетается список пунктов меню, отлчичается в заивсимости от роли
        setConfigurableUserMenu: function (state, action) {
            state.arrayForCheckBoxes = action.payload;
        },
        setClearAllForm: function (state) {
            state.dataToUpdate = {};
            state.isUpdatingFormActivated = false;
            state.typeChoosedBlock = "user-info";
            state.togglersButtons = state.togglersButtons.map(function (btn) {
                return btn.id === 1 ? __assign(__assign({}, btn), { checked: true }) : __assign(__assign({}, btn), { checked: false });
            });
        },
    },
});
export var updateUserReducer = slice.reducer;
export var setUpdateUser = (_a = slice.actions, _a.setUpdateUser), setChangeUserAvatar = _a.setChangeUserAvatar, setChangeUserCover = _a.setChangeUserCover, toggleAvatarOrCoverChanged = _a.toggleAvatarOrCoverChanged, setCheckedToggleButtons = _a.setCheckedToggleButtons, setPickCheckboxInMenu = _a.setPickCheckboxInMenu, setDataToUpdate = _a.setDataToUpdate, setUserUpdateField = _a.setUserUpdateField, setClearAllForm = _a.setClearAllForm, setPasswordsFiled = _a.setPasswordsFiled, setPasswordToUpdate = _a.setPasswordToUpdate, setConfigurableUserMenu = _a.setConfigurableUserMenu;
export var updateUserInformationTC = function (userUpdateData) { return function (dispatch) {
    userInformationAPI
        .updateUserInformation(userUpdateData)
        .then(function (res) {
        dispatch(toggleAvatarOrCoverChanged());
        dispatch(setAuthInformationUser(res.data));
    })
        .catch(function (error) {
        console.log("Данные пользователя НЕ обновились!!!" + error);
    });
}; };
export var changeUserAvatarTC = function (newAvatar) { return function (dispatch) {
    // ТИПИЗИРОВАТЬ
    userInformationAPI
        .changeUserAvatarOrCover(newAvatar)
        .then(function (res) {
        dispatch(toggleAvatarOrCoverChanged());
        dispatch(setAuthInformationUser(res.data));
        // dispatch(setChangeUserAvatar(res.data))
    })
        .catch(function (error) {
        console.log("Аватарку НЕ поменяли");
    });
}; };
export var changeUserCoverTC = function (newCover) { return function (dispatch) {
    // ТИПИЗИРОВАТЬ
    userInformationAPI
        .changeUserAvatarOrCover(newCover)
        .then(function (res) {
        dispatch(toggleAvatarOrCoverChanged());
        dispatch(setAuthInformationUser(res.data));
        // dispatch(setChangeUserCover(res.data))
    })
        .catch(function (error) {
        console.log("Cover НЕ поменяли");
    });
}; };
export var getChangeUserSettings = function (data) { return function (dispatch) {
    updateSettingUserAPI
        .getUpdateUserSettings(data)
        .then(function (res) {
        dispatch(setSuccessAlertOpenStatus({ isOpen: true }));
        dispatch(setMessageToUserForModal(res.data));
        dispatch(setModal("success-alert-modal"));
        dispatch(setActive(true));
    })
        .catch(function (error) {
        dispatch(setMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
    });
}; };
