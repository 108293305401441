var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import { createSlice } from "@reduxjs/toolkit";
import { eventsAPI, filtersAPI } from "../../../api/API";
import { setFilterEventsPublications } from "../../actionCreators/filters/setFilterEventsPublications";
var initialStateEvents = {
    eventsSortButton: [
        { id: 1, type: 1, title: "Актуальные", typeSort: "present", check: false },
        { id: 2, type: 2, title: "Прошедшие", typeSort: "past", check: false },
        // { id: 3, type: 3, title: "Календарь", check: false },
    ],
    eventsFilterChoosedCheckbox: [],
    eventsFilterState: {
        page: 2,
        // location_id: "",
        // сategory_id__in: [],
    },
    typeSortByRelevant: "present",
    isEventsPageDataLoding: true,
};
var slice = createSlice({
    name: "events",
    initialState: initialStateEvents,
    reducers: {
        // setEvents: (state, action: PayloadAction<INewsAndEventsInitialState>) => {
        //   // state.events = action.payload;
        // },
        setSlider: function (state, action) {
            state.slider = action.payload;
        },
        setAds: function (state, action) {
            state.ads = action.payload;
        },
        setToggleEventsSortButtons: function (state, action) {
            state.eventsSortButton.map(function (item) {
                if (item.id === action.payload && state.typeSortByRelevant !== item.typeSort) {
                    state.typeSortByRelevant = item.typeSort;
                    return (item.check = !item.check);
                }
                else {
                    state.typeSortByRelevant = null;
                    return (item.check = false);
                }
            });
        },
        setSortTypeInEvent: function (state, action) {
            state.typeSortByRelevant = action.payload;
        },
        setSortTypeInEventToNull: function (state) {
            state.typeSortByRelevant = null;
        },
        setEventsContent: function (state, action) {
            state.eventsContents = action.payload;
        },
        setFilterCategoriesAndLocations: function (state, action) {
            var _a, _b, _c, _d;
            state.eventsFilterLocations = (_b = (_a = action.payload.right) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.list_1;
            state.eventsFilterCategories = (_d = (_c = action.payload.right) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.list_3;
        },
        setFilterTriggeredOff: function (state) {
            state.isEventFilterActive = false;
            state.eventsContents = [];
            state.eventsFilterState = {};
        },
        setEventFilterState: function (state, action) {
            state.eventsFilterState = action.payload;
        },
        setCheckboxChecked: function (state, action) {
            state.eventsFilterCategories.map(function (item) {
                if (item.id === action.payload.id) {
                    item.check = !item.check;
                }
                return item;
            });
        },
        setCheckboxNotChecked: function (state) {
            state.eventsFilterCategories = state.eventsFilterCategories.map(function (item) {
                item.check = false;
                return item;
            });
        },
        setCheckboxCheckedActive: function (state, action) {
            state.eventsFilterCategories.map(function (item) {
                if (item.id === action.payload.id) {
                    item.check = false;
                }
                return item;
                // return (item.check === false);
            });
        },
        setEventFilterActive: function (state, action) {
            state.isEventFilterActive = action.payload;
        },
        setEventPageDataLoading: function (state, action) {
            state.isEventsPageDataLoding = action.payload.isLoading;
        },
        setIsMustToggleConcreteEventFilterButton: function (state, action) {
            state.isMustToggleConcreteEventFilterButton = action.payload.mustToggleButton;
        },
    },
    extraReducers: (_a = {},
        _a[setFilterEventsPublications.fulfilled.type] = function (state, action) {
            state.eventsContents = action.payload;
        },
        _a),
});
export var eventsReducer = slice.reducer;
export var setToggleEventsSortButtons = (_b = slice.actions, _b.setToggleEventsSortButtons), setEventsContent = _b.setEventsContent, setFilterTriggeredOff = _b.setFilterTriggeredOff, setSlider = _b.setSlider, setAds = _b.setAds, setFilterCategoriesAndLocations = _b.setFilterCategoriesAndLocations, setIsMustToggleConcreteEventFilterButton = _b.setIsMustToggleConcreteEventFilterButton, setEventFilterState = _b.setEventFilterState, setCheckboxChecked = _b.setCheckboxChecked, setEventFilterActive = _b.setEventFilterActive, setSortTypeInEvent = _b.setSortTypeInEvent, setSortTypeInEventToNull = _b.setSortTypeInEventToNull, setCheckboxCheckedActive = _b.setCheckboxCheckedActive, setEventPageDataLoading = _b.setEventPageDataLoading, setCheckboxNotChecked = _b.setCheckboxNotChecked;
export var getEventsTC = function () { return function (dispatch) {
    dispatch(setEventPageDataLoading({ isLoading: true }));
    eventsAPI
        .getEvents()
        .then(function (res) {
        dispatch(setSlider(res.data.slider));
        dispatch(setAds(res.data.ads));
        dispatch(setEventPageDataLoading({ isLoading: false }));
    })
        .catch(function (error) {
        console.log("Рекламу и баннер не получили" + error);
    });
}; };
export var fetchEventFilterCategoriesAndLocations = function () { return function (dispatch) {
    eventsAPI
        .getEvents()
        .then(function (res) {
        dispatch(setFilterCategoriesAndLocations(res.data));
    })
        .catch(function (error) {
        console.log("Рекламу и баннер не получили" + error);
    });
}; };
export var fetchEventsFeed = function (relevantType) { return function (dispatch) {
    eventsAPI
        .getEventsFeed(relevantType)
        .then(function (res) {
        dispatch(setEventsContent(res.data.results));
    })
        .catch(function (error) {
        console.log("События не получили" + error);
    });
}; };
export var fetchEventsFeedWithPagination = function (currentPage, typeSort) {
    if (currentPage === void 0) { currentPage = 1; }
    return __awaiter(void 0, void 0, void 0, function () {
        var response, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, eventsAPI.getEventsFeedWithPagination(currentPage, typeSort)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    err_1 = _a.sent();
                    console.log(err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
};
export var fetchFilteredEventsFeedWithPagination = function (currentPage, data, typeSort) {
    if (currentPage === void 0) { currentPage = 1; }
    return __awaiter(void 0, void 0, void 0, function () {
        var response, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, filtersAPI.setFilterEventsPublicationsWithPagination(currentPage, data, typeSort)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    err_2 = _a.sent();
                    console.log(err_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
};
