var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
var initialStateHeadcats = {
    сategory_id__in: [],
};
export var headcatsReducer = createSlice({
    name: "headcatsReducer",
    initialState: initialStateHeadcats,
    reducers: {
        loadHeadcats: function (state, action) {
            state.headcats = action.payload.headcats;
        },
        pushCategory: function (state, action) {
            if (action.payload.where === "toHide") {
                if (action.payload.category.name === "Все") {
                    state.сategory_id__in = [];
                    state.headcats.show = state.headcats.show.map(function (category) {
                        if (category.name === "Все") {
                            return __assign(__assign({}, category), { check: true });
                        }
                        else {
                            return __assign(__assign({}, category), { check: false });
                        }
                    });
                    state.headcats.hide = state.headcats.hide.map(function (category) {
                        return __assign(__assign({}, category), { check: false });
                    });
                }
                else {
                    state.headcats.show = state.headcats.show.map(function (sort_btn, index) {
                        if (sort_btn.name === "Все") {
                            return __assign(__assign({}, sort_btn), { check: false });
                        }
                        if (sort_btn.id === action.payload.category.id) {
                            if (!state.сategory_id__in.includes(action.payload.category.id)) {
                                state.сategory_id__in.push(action.payload.category.id);
                            }
                            else {
                                state.сategory_id__in = state.сategory_id__in.filter(function (id) { return id !== action.payload.category.id; });
                            }
                            return __assign(__assign({}, sort_btn), { check: !sort_btn.check });
                        }
                        else {
                            return sort_btn;
                        }
                    });
                }
            }
            else {
                state.headcats.hide = state.headcats.hide.map(function (sort_btn, index) {
                    if (sort_btn.id === action.payload.category.id) {
                        state.headcats.show = state.headcats.show.map(function (category) {
                            if (category.name === "Все") {
                                return __assign(__assign({}, category), { check: false });
                            }
                            else {
                                return category;
                            }
                        });
                        if (!state.сategory_id__in.includes(action.payload.category.id)) {
                            state.сategory_id__in.push(action.payload.category.id);
                        }
                        else {
                            state.сategory_id__in = state.сategory_id__in.filter(function (id) { return id !== action.payload.category.id; });
                        }
                        return __assign(__assign({}, sort_btn), { check: !sort_btn.check });
                    }
                    else {
                        return sort_btn;
                    }
                });
            }
        },
        generateCategoryInId: function (state) {
            var _a, _b;
            (_b = (_a = state === null || state === void 0 ? void 0 : state.headcats) === null || _a === void 0 ? void 0 : _a.show) === null || _b === void 0 ? void 0 : _b.forEach(function (category) {
                if (!state.сategory_id__in.includes(category.id)) {
                    state.сategory_id__in.push(category.id);
                }
            });
        },
        clearHeadcatsState: function (state) {
            state.сategory_id__in = [];
            state.headcats.show = state.headcats.show.map(function (category) {
                if (category.name === "Все") {
                    return __assign(__assign({}, category), { check: true });
                }
                else {
                    return category;
                }
            });
            state.headcats.hide = state.headcats.hide.map(function (category) {
                return __assign(__assign({}, category), { check: false });
            });
        },
    },
});
export default headcatsReducer.reducer;
export var loadHeadcats = (_a = headcatsReducer.actions, _a.loadHeadcats), pushCategory = _a.pushCategory, generateCategoryInId = _a.generateCategoryInId, clearHeadcatsState = _a.clearHeadcatsState;
