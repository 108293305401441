import { createSlice } from "@reduxjs/toolkit";
var initialStateLoading = {
    isLoading: false,
};
var loaderReduser = createSlice({
    name: 'loaderReduser',
    initialState: initialStateLoading,
    reducers: {
        changeLoading: function (state, action) {
            state.isLoading = action.payload.isLoading;
        }
    }
});
export default loaderReduser.reducer;
export var changeLoading = loaderReduser.actions.changeLoading;
