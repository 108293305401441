var _a;
import { createSlice } from "@reduxjs/toolkit";
import { getRegestration } from "./actionCreators/registrationAction";
var initialRegistrationState = {};
var registration = createSlice({
    name: 'registration',
    initialState: initialRegistrationState,
    reducers: {},
    extraReducers: (_a = {},
        _a[getRegestration.pending.type] = function (state, action) {
            state.isLoading = true;
        },
        _a[getRegestration.fulfilled.type] = function (state, action) {
            state.status = action.payload;
        },
        _a[getRegestration.rejected.type] = function (state, action) {
            state.isError = action.payload;
        },
        _a)
});
export default registration.reducer;
