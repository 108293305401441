var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
import { authAPI, registrationAPI, userInformationAPI } from "../api/API";
import { setActive, setAuthModalOpenStatus, setErrorMessageToUserForModal, setMessageToUserForModal, setModal } from "./modalReducer";
import SetCookie from "../hooks/cookies/setCookie";
import RemoveCookie from "../hooks/cookies/removeCookie";
var mailRuCode;
var mailRuRedirect;
var initialStateAuth = {
    user: {},
    isAuth: null,
    userAuthInfo: {},
    authError: "",
    timezone: {},
    isUserInfoLoading: false,
    isLoading: true,
    password: "",
    openErrorMaterialModal: false,
    errorTextModalMaterial: "",
    isNeedAutoUpdatePageAndRedirectToHome: true,
    userMenuListInProfile: [],
    isUserAuth: false,
    isLogoutAfterChangePassword: false,
    hasNotification: false,
};
var slice = createSlice({
    name: "auth",
    initialState: initialStateAuth,
    reducers: {
        setUserAuth: function (state, action) {
            state.isUserAuth = action.payload;
        },
        setUserTimeZone: function (state, action) {
            state.timezone = action.payload.timezone;
        },
        setPassword: function (state, action) {
            state.password = action.payload;
        },
        setOpenErrorMaterialModal: function (state, action) {
            state.openErrorMaterialModal = action.payload.active;
        },
        setOpenErrorMaterialModalText: function (state, action) {
            state.errorTextModalMaterial = action.payload.text;
        },
        setUserLogIn: function (state, action) {
            state.isAuth = true;
            state.authError = "";
            state.isLoading = false;
        },
        setAuthInformationUser: function (state, action) {
            var _a, _b;
            state.isAuth = true;
            state.userAuthInfo = action.payload;
            //первая кнопка делается активной о умолчанию
            state.userMenuListInProfile = (_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.menu) === null || _b === void 0 ? void 0 : _b.map(function (menu_item) {
                if (menu_item.id === 1) {
                    return __assign(__assign({}, menu_item), { check: true });
                }
                else {
                    return menu_item;
                }
            });
        },
        setLogout: function (state) {
            state.isAuth = false;
            state.user = {};
            state.userAuthInfo = {};
        },
        setAuthError: function (state, action) {
            state.authError = action.payload;
        },
        setNeedAutoUpdatePageAndRedirectToHome: function (state, action) {
            state.isNeedAutoUpdatePageAndRedirectToHome = action.payload;
        },
        changeUserInfoStatusLoading: function (state, action) {
            state.isUserInfoLoading = action.payload.isLoading;
        },
        setInfoAboutUser: function (state, action) {
            state.userAuthInfo.info.about = action.payload;
        },
        setIsLogoutAfterChangePassword: function (state, action) {
            state.isLogoutAfterChangePassword = action.payload;
        },
    },
    extraReducers: {
    // логинизация!
    // [setLoginUserThunk.pending.type]: (state) => {
    //     state.isLoading = true
    // },
    // [setLoginUserThunk.fulfilled.type]: (state, action: PayloadAction<any>) => {
    //     state.isLoading = false
    //     state.isAuth = true
    // },
    // [setLoginUserThunk.rejected.type]: (state, action: PayloadAction<string>) => {
    //     state.isLoading = false
    //     state.Error = action.payload
    // },
    // // инфо об авторизации
    // [getAuthMe.pending.type]: (state) => {
    //     state.isLoading = true
    // },
    // [getAuthMe.fulfilled.type]: (state, action: PayloadAction<any>) => {
    //     state.isLoading = false
    //     state.userAuthInfo = action.payload
    //     if (localStorage.getItem('access')) {
    //         state.isAuth = true
    //     } else {
    //         state.isAuth = false
    //     }
    // },
    // [getAuthMe.rejected.type]: (state, action: PayloadAction<string>) => {
    //     state.isLoading = false
    //     state.Error = action.payload
    // },
    },
});
export var authReducer = slice.reducer;
export var setUserAuth = (_a = slice.actions, _a.setUserAuth), setLogout = _a.setLogout, setUserTimeZone = _a.setUserTimeZone, setAuthInformationUser = _a.setAuthInformationUser, setUserLogIn = _a.setUserLogIn, setAuthError = _a.setAuthError, setPassword = _a.setPassword, setOpenErrorMaterialModal = _a.setOpenErrorMaterialModal, setInfoAboutUser = _a.setInfoAboutUser, setOpenErrorMaterialModalText = _a.setOpenErrorMaterialModalText, setNeedAutoUpdatePageAndRedirectToHome = _a.setNeedAutoUpdatePageAndRedirectToHome, setIsLogoutAfterChangePassword = _a.setIsLogoutAfterChangePassword, changeUserInfoStatusLoading = _a.changeUserInfoStatusLoading;
export var loginUser = function (data) { return function (dispatch) {
    dispatch(setPassword(data.password));
    authAPI
        .logIn(data)
        .then(function (res) {
        /* пока оставил, чтоб потом заюзать дату сгорания токена, в случае нужды
        const dataExpiration = new Date(tokenData.exp * 1000); */
        if ((res === null || res === void 0 ? void 0 : res.status) === 200) {
            SetCookie("isUserAuth", "true");
            SetCookie("access", res.data.access);
            SetCookie("refresh", res.data.refresh);
            dispatch(setUserLogIn());
            dispatch(setIsLogoutAfterChangePassword(false));
        }
        if ((res === null || res === void 0 ? void 0 : res.status) === 400) {
            dispatch(setMessageToUserForModal(res === null || res === void 0 ? void 0 : res.data));
            dispatch(setModal("error-alert-modal"));
            dispatch(setActive(true));
        }
        if (!res) {
            dispatch(setMessageToUserForModal("Вы ввели неправильный пароль или e-mail. Попробуйте еще раз."));
            dispatch(setModal("error-alert-modal"));
            dispatch(setActive(true));
        }
        return res === null || res === void 0 ? void 0 : res.data;
        // setTimeout(() => {
        //   window.location.href = "/";
        //   window.location.reload();
        // }, 100);
    })
        .catch(function (error) {
        var _a;
        if (error.response) {
            dispatch(setMessageToUserForModal((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data));
            dispatch(setModal("error-alert-modal"));
            dispatch(setActive(true));
        }
        else {
            dispatch(setMessageToUserForModal("Вы ввели неправильный пароль или e-mail. Попробуйте еще раз."));
            dispatch(setModal("error-alert-modal"));
            dispatch(setActive(true));
        }
    });
}; };
export var logoutUser = function () { return function (dispatch) {
    authAPI.logOut().then(function () {
        SetCookie("isUserAuth", "false");
        RemoveCookie("refresh");
        RemoveCookie("access");
        RemoveCookie("key");
        RemoveCookie("visit");
        RemoveCookie("isNeedRefreshToken");
        dispatch(setLogout());
        setTimeout(function () {
            window.location.reload();
        }, 100);
    });
}; };
export var logoutUserAndClearCookies = function () { return function (dispatch) {
    dispatch(setLogout());
    window.location.href = "/";
    SetCookie("isUserAuth", "false");
    RemoveCookie("refresh");
    RemoveCookie("access");
    RemoveCookie("key");
    RemoveCookie("visit");
    RemoveCookie("isNeedRefreshToken");
    RemoveCookie("needLogoutAllAndClearCookie");
    setTimeout(function () {
        window.location.reload();
    }, 100);
}; };
export var logoutUserWhenResetPassword = function (navigate) { return function (dispatch) {
    authAPI.logOutAll().then(function () {
        dispatch(setLogout());
        dispatch(setIsLogoutAfterChangePassword(true));
        RemoveCookie("refresh");
        RemoveCookie("access");
        RemoveCookie("visit");
        RemoveCookie("isUserAuth");
        SetCookie("isUserAuth", "false");
        RemoveCookie("isNeedRefreshToken");
        RemoveCookie("key");
        setTimeout(function () {
            navigate("/");
            dispatch(setAuthModalOpenStatus({ isOpen: true }));
        }, 100);
    });
}; };
export var getAuthUserData = function () { return function (dispatch) {
    authAPI
        .authMe()
        .then(function (res) {
        if (res.status !== 401) {
            dispatch(setAuthInformationUser(res.data));
        }
    })
        .catch(function (error) { });
}; };
/////  resetPasswordThank
export var RefreshPassword = function (oldPassWord, newPassword) { return function (dispatch) {
    authAPI
        .refreshPassword(oldPassWord, newPassword)
        .then(function (res) {
        dispatch(setMessageToUserForModal(res.data));
        dispatch(setModal("success-alert-modal"));
        dispatch(setActive(true));
        // setTimeout(() => {
        //   dispatch(setActive(false));
        // }, 2000);
    })
        .catch(function (error) {
        dispatch(setErrorMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
    });
}; };
export var RefreshPasswordWithEmail = function (email) { return function (dispatch) {
    authAPI
        .RefreshPasswordWithEmail(email)
        .then(function (res) {
        dispatch(setMessageToUserForModal(res === null || res === void 0 ? void 0 : res.data));
        dispatch(setModal("success-alert-modal"));
        dispatch(setActive(true));
    })
        .catch(function (error) {
        dispatch(setErrorMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
    });
}; };
export var RefreshPasswordBeforeEmail = function (newPassword, token) { return function (dispatch) {
    authAPI
        .RefreshPasswordBeforeEmail(newPassword, token)
        .then(function (res) {
        if (res.status === 400) {
            dispatch(setMessageToUserForModal(res === null || res === void 0 ? void 0 : res.data));
            dispatch(setModal("success-alert-modal"));
            dispatch(setActive(true));
        }
        if (res.status === 200 || res.status === 201) {
            dispatch(setMessageToUserForModal(res === null || res === void 0 ? void 0 : res.data));
            dispatch(setModal("success-alert-modal"));
            dispatch(setActive(true));
            setTimeout(function () {
                window.location.href = "https://itnews.pro/";
            }, 1000);
        }
    })
        .catch(function (error) {
        dispatch(setErrorMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
    });
}; };
export var getAccessWithKEYforSocial = function () { return function (dispatch) {
    authAPI.getKeyForTokken().then(function (res) {
        SetCookie("isUserAuth", "true");
        SetCookie("access", res.data.access);
        SetCookie("refresh", res.data.refresh);
        dispatch(setIsLogoutAfterChangePassword(false));
        setTimeout(function () {
            dispatch(getAuthUserData());
        }, 100);
    });
}; };
export var getRegestration = function (data) { return function (dispatch) {
    registrationAPI
        .registration(data)
        .then(function (res) {
        if (res.status === 200 || res.status === 201) {
            dispatch(setMessageToUserForModal(res.data));
            dispatch(setModal("success-alert-modal"));
            dispatch(setActive(true));
        }
        if (res.status === 400) {
            dispatch(setMessageToUserForModal(res.data));
            dispatch(setModal("error-alert-modal"));
            dispatch(setActive(true));
        }
    })
        .catch(function (error) {
        dispatch(setMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
    });
}; };
export var getRegestrationCompany = function (data) { return function (dispatch) {
    registrationAPI
        .getRegistrationCompany(data)
        .then(function (res) {
        if (res.status === 200 || res.status === 201) {
            dispatch(setMessageToUserForModal(res.data));
            dispatch(setModal("success-alert-modal"));
            dispatch(setActive(true));
        }
        if (res.status === 400) {
            dispatch(setMessageToUserForModal(res.data));
            dispatch(setModal("error-alert-modal"));
            dispatch(setActive(true));
        }
    })
        .catch(function (error) {
        dispatch(setMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
    });
}; };
export var getRegestrationExpert = function (data) { return function (dispatch) {
    registrationAPI
        .getRegistrationExpert(data)
        .then(function (res) {
        if (res.status === 200 || res.status === 201) {
            dispatch(setMessageToUserForModal(res.data));
            dispatch(setModal("success-alert-modal"));
            dispatch(setActive(true));
        }
        if (res.status === 400) {
            dispatch(setMessageToUserForModal(res.data));
            dispatch(setModal("error-alert-modal"));
            dispatch(setActive(true));
        }
    })
        .catch(function (error) {
        dispatch(setMessageToUserForModal(error.response.data));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
    });
}; };
export var refreshInfoAboutUser = function (infoAbout) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, userInformationAPI.updateUserInformation(infoAbout)];
            case 1:
                response = _a.sent();
                if (response) {
                    return [2 /*return*/, response.data];
                }
                return [3 /*break*/, 3];
            case 2:
                err_1 = _a.sent();
                console.log("не удалось получить ленту вакансий компании");
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
