import { createSlice } from "@reduxjs/toolkit";
var initialStateBookmarkStatus = {
    isAddedOrRemovedFromAdsBlock: false,
};
var AdminAdsModeration = createSlice({
    name: "adminAdsModeration",
    initialState: initialStateBookmarkStatus,
    reducers: {
        changeNeedToReloadAdsBlock: function (state) {
            state.isAddedOrRemovedFromAdsBlock = !state.isAddedOrRemovedFromAdsBlock;
        },
    },
});
export default AdminAdsModeration.reducer;
export var changeNeedToReloadAdsBlock = AdminAdsModeration.actions.changeNeedToReloadAdsBlock;
