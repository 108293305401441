var _a;
import { createSlice } from "@reduxjs/toolkit";
import { fetchUserById } from "../../actionCreators/fetchUserById/fetchUserById";
var initialStateGetUserById = {};
var slice = createSlice({
    name: 'getUserById',
    initialState: initialStateGetUserById,
    reducers: {},
    extraReducers: (_a = {},
        _a[fetchUserById.pending.type] = function (state) { state.isLoading = true; },
        _a[fetchUserById.fulfilled.type] = function (state, action) {
            state.isLoading = false;
            state.UserByID = action.payload;
        },
        _a[fetchUserById.rejected.type] = function (state, action) { state.Error = action.payload; },
        _a)
});
export default slice.reducer;
