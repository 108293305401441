var _a;
import { createSlice } from "@reduxjs/toolkit";
import { feedbackAPI } from "../api/API";
import { changeLoading } from "./loaderReduser";
import { setActive, setMessageToUserForModal, setModal } from "./modalReducer";
var initialFeedbackState = {
    name: '',
    email: '',
    phone: '',
    question: '',
};
var FeedbackReduser = createSlice({
    name: "feedback",
    initialState: initialFeedbackState,
    reducers: {},
});
export default FeedbackReduser.reducer;
export var _b = _a = FeedbackReduser.actions;
export var addFeedbackTC = function (data) { return function (dispatch) {
    dispatch(changeLoading({ isLoading: true }));
    feedbackAPI
        .addFeedbackTC(data)
        .then(function (res) {
        dispatch(changeLoading({ isLoading: false }));
        dispatch(setModal("success-alert-modal"));
        dispatch(setActive(true));
        dispatch(setMessageToUserForModal(res.data));
    })
        .catch(function (res) {
        dispatch(changeLoading({ isLoading: false }));
        dispatch(setModal("success-alert-modal"));
        dispatch(setActive(true));
        dispatch(setMessageToUserForModal(res.data));
    });
}; };
