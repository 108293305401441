var _a;
import { createSlice } from "@reduxjs/toolkit";
import { setFilterEventsPublications } from "../../actionCreators/filters/setFilterEventsPublications";
var initialStateFilteredEvents = {};
var slice = createSlice({
    name: 'filteredEvents',
    initialState: initialStateFilteredEvents,
    reducers: {
        setClearFilteredEvents: function (state) {
            state.FilteredEventsResponse = {};
        }
    },
    extraReducers: (_a = {},
        _a[setFilterEventsPublications.pending.type] = function (state) {
            state.isLoading = true;
        },
        _a[setFilterEventsPublications.fulfilled.type] = function (state, action) {
            state.isLoading = false;
            state.FilteredEventsResponse = action.payload;
        },
        _a[setFilterEventsPublications.rejected.type] = function (state, action) {
            state.isLoading = false;
            state.Error = action.payload;
        },
        _a)
});
export default slice.reducer;
export var setClearFilteredEvents = slice.actions.setClearFilteredEvents;
