var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
import { expertAPI } from "../../../api/API";
var initialStateExpertrofile = {
    expertProfileBlockButtons: [
        { id: 1, type: 1, title: "Публикации", check: false },
        { id: 2, type: 2, title: "Избранные публикации", check: false },
        { id: 3, type: 3, title: "Обо мне", check: false },
        { id: 4, type: 4, title: "Избранные события", check: false },
        { id: 5, type: 5, title: "Избранные вакансии", check: false },
        { id: 6, type: 6, title: "Лента", check: false },
    ],
    expertProfileBlockButtonsForMobile: [
        { id: 1, type: 1, title: "Публикации", check: false },
        { id: 2, type: 2, title: "Избранные публикации", check: false },
        { id: 3, type: 3, title: "Обо мне", check: false },
        { id: 4, type: 4, title: "Избранные события", check: false },
        { id: 5, type: 5, title: "Избранные вакансии", check: false },
        { id: 6, type: 6, title: "Лента", check: false },
        { id: 8, type: 8, typeForSearch: 3, title: "Подписки", check: false },
        { id: 9, type: 9, typeForSearch: 4, title: "Подписчики", check: false },
    ],
    menuListItemFromBack: [],
    publicStatusButtonFilter: [
        { id: 1, type: 1, moderation_status: 3, title: "Опубликовано", check: true },
        { id: 2, type: 2, moderation_status: 1, title: "На модерации", check: false },
        { id: 3, type: 3, moderation_status: 2, title: "Отклоненные", check: false },
        { id: 4, type: 4, moderation_status: 0, title: "Черновик", check: false },
    ],
    type: 1,
    typeFeed: "user",
    moderation_status: 3,
    publickStatusButtonActiveId: 0,
    dataInExpertProfile: [],
};
var ExpertProfileReducer = createSlice({
    name: "expertProfile",
    initialState: initialStateExpertrofile,
    reducers: {
        setToggleExpertProfileBlockButtons: function (state, action) {
            state.menuListItemFromBack.map(function (item) {
                if (item.id === action.payload.id) {
                    state.type = action.payload.id;
                    if (item.title === "Публикации") {
                        state.typeFeed = "user";
                        state.typeLentForBookmarked = "lent";
                    }
                    if (item.title === "Избранные публикации") {
                        state.typeLentForBookmarked = "lent";
                    }
                    if (item.title === "Избранные события") {
                        state.typeLentForBookmarked = "event";
                    }
                    state.typeSearch = action.payload.typeSearch;
                    return (item.check = true);
                }
                else {
                    return (item.check = false);
                }
            });
        },
        setToggleExpertProfileBlockButtonsForMobile: function (state, action) {
            state.expertProfileBlockButtonsForMobile.map(function (item) {
                if (item.id === action.payload.id) {
                    state.type = action.payload.id;
                    state.typeSearch = action.payload.typeForSearch;
                    return (item.check = true);
                }
                else {
                    return (item.check = false);
                }
            });
        },
        resetPublickStatusButtonActiveIdExpert: function (state, action) {
            state.publickStatusButtonActiveId = action.payload;
        },
        setMenuListItemForExprt: function (state, action) {
            state.menuListItemFromBack = action.payload.map(function (menuItem) {
                return menuItem.type === 1 ? __assign(__assign({}, menuItem), { check: true }) : menuItem;
            });
        },
        resetStateFilterExpertButton: function (state) {
            state.publicStatusButtonFilter = state.publicStatusButtonFilter.map(function (item) {
                return item.type === 1 ? __assign(__assign({}, item), { check: true }) : __assign(__assign({}, item), { check: false });
            });
            state.moderation_status = 3;
            state.publickStatusButtonActiveId = 1;
            state.typeFeed = "user";
        },
        resetMainButtonExpertButton: function (state) {
            state.type = 1;
            state.menuListItemFromBack = state.menuListItemFromBack.map(function (menu_item) {
                if (menu_item.id === 1) {
                    return __assign(__assign({}, menu_item), { check: true });
                }
                else {
                    return menu_item;
                }
            });
        },
        setToggleStatePublicationExpertFilterButton: function (state, action) {
            state.publicStatusButtonFilter.map(function (item) {
                if (item.id === action.payload) {
                    state.moderation_status = item === null || item === void 0 ? void 0 : item.moderation_status;
                    state.publickStatusButtonActiveId = action.payload;
                    return (item.check = true);
                }
                else {
                    return (item.check = false);
                }
            });
        },
        setDetails: function (state, action) {
            state.dataInExpertProfile = action.payload;
        },
        clearRightSetData: function (state) {
            state.dataRightSetForExpertProfile = {};
        },
        setTypeForSearch: function (state, action) {
            state.typeSearch = action.payload;
        },
        setRightSetDataInExpertProfile: function (state, action) {
            state.dataRightSetForExpertProfile = action.payload;
        },
    },
});
export default ExpertProfileReducer.reducer;
export var setToggleExpertProfileBlockButtons = (_a = ExpertProfileReducer.actions, _a.setToggleExpertProfileBlockButtons), setToggleExpertProfileBlockButtonsForMobile = _a.setToggleExpertProfileBlockButtonsForMobile, setTypeForSearch = _a.setTypeForSearch, setDetails = _a.setDetails, resetStateFilterExpertButton = _a.resetStateFilterExpertButton, resetMainButtonExpertButton = _a.resetMainButtonExpertButton, setRightSetDataInExpertProfile = _a.setRightSetDataInExpertProfile, setToggleStatePublicationExpertFilterButton = _a.setToggleStatePublicationExpertFilterButton, resetPublickStatusButtonActiveIdExpert = _a.resetPublickStatusButtonActiveIdExpert, setMenuListItemForExprt = _a.setMenuListItemForExprt, clearRightSetData = _a.clearRightSetData;
//THUNKS FOR NEW QUERIES
export var fetchUserFeedPublicationOrEventsInExpertProfile = function (currentPage, typeFeed, body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, expertAPI.getUserFeedPublicationsOrEventsInExpertProfile(currentPage, typeFeed, body)];
            case 1:
                response = _a.sent();
                if (response) {
                    return [2 /*return*/, response.data];
                }
                return [3 /*break*/, 3];
            case 2:
                err_1 = _a.sent();
                console.log("не удалось получить ленту компании");
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
// export const fetchUserFeedVacancies = async (currentPage: number, body: any) => {
//   try {
//     const response = await companyAPI.getUserFeedVacancies(currentPage, body);
//     if (response) {
//       return response.data;
//     }
//   } catch (err) {
//     console.log("не удалось получить ленту вакансий компании");
//   }
// };
export var fetchUserFeedBookmarkedPublicationsOrEventsInExpertProfile = function (currentPage, typeLent) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, expertAPI.getUserFeedBookmarkedPublicationsOrEventsInUserProfile(currentPage, typeLent)];
            case 1:
                response = _a.sent();
                if (response) {
                    return [2 /*return*/, response.data];
                }
                return [3 /*break*/, 3];
            case 2:
                err_2 = _a.sent();
                console.log("не удалось получить ленту вакансий компании");
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var fetchUserFeedBookmarkedVacanciesForExpertProfile = function (currentPage) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, expertAPI.getBookmarkedVacanciesInExpertProfile(currentPage)];
            case 1:
                response = _a.sent();
                if (response) {
                    return [2 /*return*/, response.data];
                }
                return [3 /*break*/, 3];
            case 2:
                err_3 = _a.sent();
                console.log("не удалось получить ленту вакансий компании");
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var fetchUserFeedFollowsForExpertProfile = function (currentPage) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, expertAPI.getFeedFollowsOnExpertProfile(currentPage)];
            case 1:
                response = _a.sent();
                if (response) {
                    return [2 /*return*/, response.data];
                }
                return [3 /*break*/, 3];
            case 2:
                err_4 = _a.sent();
                console.log("не удалось получить ленту вакансий компании");
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
