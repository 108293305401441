import { createSlice } from "@reduxjs/toolkit";
import { likeAPI } from "../api/API";
var initialStateLikeStatus = {};
var slice = createSlice({
    name: 'likes',
    initialState: initialStateLikeStatus,
    reducers: {
        setAddOrDeleteLike: function (state, action) {
            return action.payload;
        }
    },
});
export var likesReducer = slice.reducer;
export var setAddOrDeleteLike = slice.actions.setAddOrDeleteLike;
export var addOrDeleteLikeTC = function (id) { return function (dispatch) {
    likeAPI.addLike(id)
        .then(function (res) {
        dispatch(setAddOrDeleteLike(res.data));
        console.log('Добавели/Удалили, LIKE', res.data);
    })
        .catch(function (error) {
        console.log("LIKE НЕ получилось добавить" + error);
    });
}; };
