var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { vacanciesApi } from "../api/API";
import { setOpenErrorMaterialModal, setOpenErrorMaterialModalText } from "./authReducer";
import { changeLoading } from "./loaderReduser";
var initialVacanciesState = {
    vacancyPageInfo: {},
    allVacancies: {},
    categoryForCreateVacancies: [],
    sityForCreateVacancies: "",
    formForCreateVacancies: [],
    vacancyDataById: [],
    vacancyFilterParameters: {
        word: "",
        form__name: "",
        city__name: "",
        category__name__in: [],
    },
    isVacancyFilterActivated: false,
};
var VacanciesReduser = createSlice({
    name: "VacanciesReduser",
    initialState: initialVacanciesState,
    reducers: {
        allVacanciesData: function (state, action) {
            state.allVacancies = action.payload;
        },
        allCategoryCreateInVacancy: function (state, action) {
            state.categoryForCreateVacancies = action.payload;
        },
        allSityInCreateVacancy: function (state, action) {
            state.sityForCreateVacancies = action.payload;
        },
        allFormInCreateVacancy: function (state, action) {
            state.formForCreateVacancies = action.payload;
        },
        pushVacancyDataById: function (state, action) {
            state.vacancyDataById = action.payload;
        },
        setVacancyPageInfo: function (state, action) {
            var _a, _b;
            state.vacancyPageInfo = action.payload;
            state.allFilter = (_a = action.payload.right) === null || _a === void 0 ? void 0 : _a[0];
            state.categoriesForFilter = (_b = action.payload.right) === null || _b === void 0 ? void 0 : _b[0].list_3;
        },
        /*     setCheckboxChecked: (state, action) => {
          state.eventsFilterCategories.map((item) => {
            if (item.id === action.payload.id) {
              item.check = !item.check;
            }
            return item;
          });
        }, */
        setCheckedInVacancyFilter: function (state, action) {
            state.categoriesForFilter.map(function (item) {
                if (item.id === action.payload.id) {
                    item.check = !item.check;
                }
                return item;
            });
        },
        setCheckedFilterToDefault: function (state) {
            state.categoriesForFilter = state.categoriesForFilter.map(function (item) {
                item.check = false;
                return item;
            });
        },
        setVacanciesFilterOff: function (state) {
            state.vacancyFilterParameters = {};
            state.isVacancyFilterActivated = false;
        },
        getClearAllVacansiesData: function (state) {
            state.allVacancies = [];
        },
        vacancyFilterActivated: function (state, action) {
            state.isVacancyFilterActivated = action.payload;
        },
        setVacancyFilterParameters: function (state, action) {
            state.vacancyFilterParameters = action.payload;
        },
    },
});
export default VacanciesReduser.reducer;
export var allVacanciesData = (_a = VacanciesReduser.actions, _a.allVacanciesData), allCategoryCreateInVacancy = _a.allCategoryCreateInVacancy, allSityInCreateVacancy = _a.allSityInCreateVacancy, allFormInCreateVacancy = _a.allFormInCreateVacancy, pushVacancyDataById = _a.pushVacancyDataById, setVacancyPageInfo = _a.setVacancyPageInfo, setVacanciesFilterOff = _a.setVacanciesFilterOff, getClearAllVacansiesData = _a.getClearAllVacansiesData, vacancyFilterActivated = _a.vacancyFilterActivated, setVacancyFilterParameters = _a.setVacancyFilterParameters, setCheckedInVacancyFilter = _a.setCheckedInVacancyFilter, setCheckedFilterToDefault = _a.setCheckedFilterToDefault;
export var getAllVacansiesData = function () { return function (dispatch) {
    // dispatch(changeLoading({ isLoading: true }))
    vacanciesApi
        .getAllVacancies()
        .then(function (res) {
        dispatch(allVacanciesData(res.data));
        // dispatch(changeLoading({ isLoading: false }))
    })
        .catch(function (error) {
        console.log(error);
        // dispatch(changeLoading({ isLoading: false }))
    });
}; };
export var fetchVacansiesPageInfo = function () { return function (dispatch) {
    vacanciesApi
        .getVacancyPageData()
        .then(function (res) {
        dispatch(setVacancyPageInfo(res.data));
    })
        .catch(function (error) {
        console.log(error);
    });
}; };
export var fetchFilteredVacansies = function (data) { return function (dispatch) {
    vacanciesApi
        .setFilterVacancies(data)
        .then(function (res) {
        dispatch(allVacanciesData(res.data));
        dispatch(vacancyFilterActivated(true));
    })
        .catch(function (error) {
        console.log(error);
    });
}; };
export var CaregoriesForCreateVacancy = function () { return function (dispatch) {
    vacanciesApi
        .getCategoryForCreateVacancy()
        .then(function (res) {
        dispatch(allCategoryCreateInVacancy(res.data));
    })
        .catch(function (error) {
        console.log(error);
    });
}; };
export var SityForCreateVacancy = function () { return function (dispatch) {
    vacanciesApi
        .getSityListForCreateVacancy()
        .then(function (res) {
        dispatch(allSityInCreateVacancy(res.data));
    })
        .catch(function (error) {
        console.log(error);
    });
}; };
export var FormForCreateVacancy = function () { return function (dispatch) {
    vacanciesApi
        .getFormCreateVacancy()
        .then(function (res) {
        dispatch(allFormInCreateVacancy(res.data));
    })
        .catch(function (error) {
        console.log(error);
    });
}; };
export var addVacancy = function (data) { return function (dispatch) {
    dispatch(changeLoading({ isLoading: true }));
    vacanciesApi
        .addVacancy(data)
        .then(function (res) {
        var _a;
        dispatch(setOpenErrorMaterialModalText({ text: (_a = res.data) === null || _a === void 0 ? void 0 : _a.detail }));
        dispatch(setOpenErrorMaterialModal({ active: true }));
        dispatch(changeLoading({ isLoading: false }));
    })
        .catch(function (res) {
        dispatch(setOpenErrorMaterialModalText({ text: "Вакансия не создана" }));
        dispatch(setOpenErrorMaterialModal({ active: true }));
        dispatch(changeLoading({ isLoading: false }));
    });
}; };
export var RefreshVacancyById = function (id, data) { return function (dispatch) {
    dispatch(changeLoading({ isLoading: true }));
    vacanciesApi
        .refreshDataByVacancyId(id, data)
        .then(function (res) {
        var _a;
        dispatch(setOpenErrorMaterialModalText({ text: (_a = res.data) === null || _a === void 0 ? void 0 : _a.detail }));
        dispatch(setOpenErrorMaterialModal({ active: true }));
        dispatch(changeLoading({ isLoading: false }));
    })
        .catch(function (error) {
        dispatch(setOpenErrorMaterialModalText({ text: "Вакансия не создана" }));
        dispatch(setOpenErrorMaterialModal({ active: true }));
        dispatch(changeLoading({ isLoading: false }));
    });
}; };
export var getDataByVacancyId = function (id) { return function (dispatch) {
    dispatch(changeLoading({ isLoading: true }));
    vacanciesApi
        .getDataByVacancyId(id)
        .then(function (res) {
        dispatch(pushVacancyDataById(res.data));
        dispatch(changeLoading({ isLoading: false }));
    })
        .catch(function (error) {
        console.log(error);
    })
        .finally(function () {
        dispatch(changeLoading({ isLoading: false }));
    });
}; };
export var fetchVacanciesOnVacancyPageWithPagination = function (currentPage) {
    if (currentPage === void 0) { currentPage = 1; }
    return __awaiter(void 0, void 0, void 0, function () {
        var response, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, vacanciesApi.getAllVacanciesWithPagination(currentPage)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    err_1 = _a.sent();
                    console.log(err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
};
export var fetchFilteredVacansiesWithPagination = function (pageNumber, data) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, vacanciesApi.setFilterVacanciesWithPagination(pageNumber, data)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_2 = _a.sent();
                console.log(err_2);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var setBookmarkVacancy = createAsyncThunk("vacancy/setBookmarkVacancy", function (id, kvAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, vacanciesApi.setBookmarkVacancy(id)];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                e_1 = _a.sent();
                kvAPI.rejectWithValue("не удалось добавить в закладки вакансию");
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
